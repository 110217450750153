/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";
import "./scss/styles.scss";

import $ from "jquery";
export const onInitialClientRender = () => {
  // for dropdown navbar menu
  $(".dropdown-hoverable").hover(
    function () {
      $(this).children('[data-toggle="dropdown"]').click();
    },
    function () {
      $(this).children('[data-toggle="dropdown"]').click();
    }
  );
  $(".carousel").carousel();
};
