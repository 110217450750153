// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-artistes-artistes-jsx": () => import("./../src/templates/artistes/artistes.jsx" /* webpackChunkName: "component---src-templates-artistes-artistes-jsx" */),
  "component---src-templates-cours-cours-dessin-peinture-jsx": () => import("./../src/templates/cours/coursDessinPeinture.jsx" /* webpackChunkName: "component---src-templates-cours-cours-dessin-peinture-jsx" */),
  "component---src-templates-cours-cours-enfants-jsx": () => import("./../src/templates/cours/coursEnfants.jsx" /* webpackChunkName: "component---src-templates-cours-cours-enfants-jsx" */),
  "component---src-templates-cours-cours-sculpture-modelage-jsx": () => import("./../src/templates/cours/coursSculptureModelage.jsx" /* webpackChunkName: "component---src-templates-cours-cours-sculpture-modelage-jsx" */),
  "component---src-templates-impressions-jsx": () => import("./../src/templates/impressions.jsx" /* webpackChunkName: "component---src-templates-impressions-jsx" */),
  "component---src-templates-informations-pratiques-jsx": () => import("./../src/templates/informationsPratiques.jsx" /* webpackChunkName: "component---src-templates-informations-pratiques-jsx" */),
  "component---src-templates-presentation-jsx": () => import("./../src/templates/presentation.jsx" /* webpackChunkName: "component---src-templates-presentation-jsx" */),
  "component---src-templates-stages-stages-jsx": () => import("./../src/templates/stages/stages.jsx" /* webpackChunkName: "component---src-templates-stages-stages-jsx" */)
}

